<template>
  <div id="webchat" />
</template>

<script>
export default {
  mounted() {
    const script = document.createElement('script')
    script.src = 'https://cdn.botpress.cloud/webchat/v1/inject.js'
    script.async = true
    document.body.appendChild(script)

    script.onload = () => {
      window.botpressWebChat.init({
        "composerPlaceholder": "Chat with biterange",
        "botConversationDescription": "Biterange live chat",
        "botId": "18d0fb74-3c42-46b4-a28c-1c6247d957e7",
        "hostUrl": "https://cdn.botpress.cloud/webchat/v1",
        "messagingUrl": "https://messaging.botpress.cloud",
        "clientId": "18d0fb74-3c42-46b4-a28c-1c6247d957e7",
        "webhookId": "147e8f68-fc87-4ed8-a9f7-e041b313d23c",
        "lazySocket": true,
        "themeName": "prism",
        "botName": "Biterange live chat",
        "avatarUrl": "https://i.pinimg.com/736x/28/b8/20/28b820a8692bc242e72c0b0302d9c794.jpg",
        "frontendVersion": "v1",
        "theme": "prism",
        "themeColor": "#2563eb",
        "allowedOrigins": []
      });
    }
  }
}
</script>

<style scoped>

</style>