import Vue from 'vue'
import VueRouter from 'vue-router'
import PageNotFound from './pages/PageNotFound.vue'
import Home from './pages/Home.vue'
import Profile from './pages/Profile.vue'
import PaymentSuccessPage from './pages/PaymentSuccessPage.vue'
import PaymentFaliPage from './pages/PaymentFaliPage.vue'
import TextPage from './pages/TextPage.vue'
import Faq from './pages/Faq.vue'
import BuyCrypto from './pages/BuyCrypto.vue'
import OrderDetails from './pages/OrderDetails.vue'
import Confirmation from './pages/Confirmation.vue'
import OrderRequisites from "@/pages/OrderRequisites.vue";
import EmailAlreadyVerified from './pages/EmailAlreadyVerified.vue'
import EmailVerificationNotice from './pages/EmailVerificationNotice.vue'
import EmailVerificationSuccess from './pages/EmailVerificationSuccess.vue'
import PaymentPendingPage from "@/pages/PaymentPendingPage.vue";

Vue.use(VueRouter)

const router = new VueRouter({
    routes: [
        {path: "/", redirect: "/home"},
        {
            path: "/home",
            name: "Home",
            component: Home,
        },
        {
            path: "/profile",
            name: "Profile",
            component: Profile,
            meta: {requiresAuth: true},
        },
        {
            path: "/success-payment",
            name: "PaymentSuccessPage",
            component: PaymentSuccessPage,
        },
        {
            path: "/fail-payment",
            name: "PaymentFaliPage",
            component: PaymentFaliPage,
        },
        {
            path: '/processing-payment',
            name: 'PaymentPendingPage',
            component: PaymentPendingPage
        },
        {
            path: "/pages/:id",
            name: "TextPage",
            component: TextPage,
        },
        {
            path: "/faq",
            name: "Faq",
            component: Faq,
        },
        {
            path: "/buy-crypto",
            name: "BuyCrypto",
            component: BuyCrypto,
        },
        {
            path: "/order-details",
            name: "OrderDetails",
            component: OrderDetails,
            meta: {requiresAuth: true},
        },
        {
            path: "/order-requisites/:uuid",
            name: "OrderRequisites",
            component: OrderRequisites,
            meta: {
                requiresAuth: true, // This route requires authentication
            },
        },
        {
            path: "/confirmation",
            name: "Confirmation",
            component: Confirmation,
            meta: {requiresAuth: true},
        },
        {
            path: "/email/verify/already-success",
            name: "EmailAlreadyVerified",
            component: EmailAlreadyVerified,
        },
        {
            path: "/email/verify/success",
            name: "EmailVerificationSuccess",
            component: EmailVerificationSuccess,
        },
        {
            path: "/email/verify/notice",
            name: "EmailVerificationNotice",
            component: EmailVerificationNotice,
        },
        {
            path: "*",
            name: "PageNotFound",
            component: PageNotFound,
            props: {title: "PageNotFound"},
        },
    ],
});

router.beforeEach((to, from, next) => {
    let isAuthenticated = localStorage.getItem("isAuthenticated");
    let emailVerified = localStorage.getItem("emailVerified");

    if (isAuthenticated !== null) {
        isAuthenticated = JSON.parse(isAuthenticated);
    } else {
        isAuthenticated = false;
    }

    if (emailVerified !== null) {
        emailVerified = JSON.parse(emailVerified);
    } else {
        emailVerified = false;
    }

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (!isAuthenticated) {
            Vue.$toast.info("Please log in first");
            next({name: "Home", query: {redirect: to.path}});
        } else if (!emailVerified) {
            Vue.$toast.info("Please verify your email");
            next({name: "EmailVerificationNotice"});
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
