<template>
  <main class="main page-inside faq-page">
    <div class="main-wrapper">
      <div class="section section-7 hero-section">
        <div class="wrapper">
          <div class="title big white">
            FAQ
          </div>
          <div class="content">
            <div class="accordion">
              <div class="item" v-for="(item, index) in $parent.faq" :key="index">
                <div class="faq-question" @click="toggleAnswer(index)">
                  <div :class="['title small left', {'active': isActiveAnswer(index)}]">{{ item.question }}</div>
                  <img :class="{'rotate-icon': isActiveAnswer(index)}" src="./../images/arrow.svg" class="img"/>
                </div>
                <transition name="fade">
                  <div v-html="item.answer" v-if="isActiveAnswer(index)" class="desc faq-answer">
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'Faq',
  props: [],
  components: {
  },
  data: function() {
    return {
      activePanels: []
    }
  },
  methods: {
   toggleAnswer(index) {
      if (this.isActiveAnswer(index)) {
        this.activePanels.splice(this.activePanels.indexOf(index), 1)
      } else {
        this.activePanels.push(index)
      }
    },
    isActiveAnswer(index) {
      return this.activePanels.includes(index)
    },
  }
}
</script>